import { Mesh, Vector3, Line3, MeshStandardMaterial, Triangle, BufferAttribute, Color } from "three";
import { IndexedTriangle } from "@/three/math/IndexedTriangle";
import { PlanarSurface, SurfaceData, SURFACE_TYPE } from "./PlanarSurface";
import { Apartment } from "../building-objects/Apartment";
import { Room } from "../building-objects/Room";
import { getObjectParameters, createTextHelper, createLinesHelper } from "../ThreeJsHelpers";

/** A tool to create surfaces for space meshes.*/
export class SurfacesGenerator {
  public readonly planarSurfaces: PlanarSurface[] = [];
  public readonly defaultMaterial?: MeshStandardMaterial;
  private readonly apartment: Apartment;
  private readonly spaceId: string;
  private readonly room: Room;

  constructor(room: Room, apartment: Apartment) {
    this.spaceId = getObjectParameters(room.spaceMesh).get("GlobalId")!;
    this.room = room;

    this.apartment = apartment;
    let planarSurfaces = this._createSurfacesFromRoom(room);
    planarSurfaces = planarSurfaces?.filter((surface) => !!surface) ?? [];
    this.planarSurfaces = planarSurfaces;
  }

  private _createSurfacesFromRoom(room: Room): PlanarSurface[] | null {
    let planarSurfaces: PlanarSurface[] = [];

    const spaceId = getObjectParameters(room.spaceMesh).get("GlobalId")!;

    const edgeLineGroups = this._edgeLineGroupsFromMesh(room.spaceMesh);
    planarSurfaces = this._surfacesFromEdgeLineGroups(edgeLineGroups, spaceId);

    const initialWallSurface = this._getInitialSurface(planarSurfaces);

    if (!initialWallSurface) {
      console.error(`Could not create geometry for space: ${spaceId}`);
      return null;
    }

    initialWallSurface.setupLocalSpace();
    initialWallSurface.setupConnectedSurfaces(SURFACE_TYPE.wall);
    initialWallSurface.setupConnectedSurfaces(SURFACE_TYPE.ceiling);
    initialWallSurface.setupConnectedSurfaces(SURFACE_TYPE.floor);

    this._setupStragglerSurfaces(planarSurfaces.filter((surface) => !surface.localOutline));

    planarSurfaces.forEach((surface) => surface.setupLocalSpace());

    const updateConnectedHoleSides = true;
    const walls = planarSurfaces.filter((surface) => surface.surfaceType === SURFACE_TYPE.wall);
    const ceilingFloors = planarSurfaces.filter((surface) => surface.surfaceType !== SURFACE_TYPE.wall);

    // Update walls first so that ceiling and floor molding points are populated
    walls.forEach((surface) => surface.updatePlanarGeometry(updateConnectedHoleSides));
    ceilingFloors.forEach((surface) => surface.updatePlanarGeometry(updateConnectedHoleSides));

    return planarSurfaces;
  }

  private _getInitialSurface(planarSurfaces: PlanarSurface[]): PlanarSurface {
    const wallPlanarSurfaces = planarSurfaces.filter((surface) => surface.surfaceType === SURFACE_TYPE.wall);

    // TODO: Prioritize wall with largest windows as initialWallSurface
    const initialWallSurface = wallPlanarSurfaces.find((wallSurface) => {
      const connectedSurfaces = wallSurface.getNeighborSurfaces();

      const initialCeillingSurface = connectedSurfaces.find(
        (connectedSurface) => connectedSurface.surfaceType === SURFACE_TYPE.ceiling
      );

      const initialFloorSurface = connectedSurfaces.find(
        (connectedSurface) => connectedSurface.surfaceType === SURFACE_TYPE.floor
      );

      return !!initialCeillingSurface && !!initialFloorSurface;
    })!;
    return initialWallSurface;
  }

  /** Assuming lines are closed. return area of polygon. */
  private _areaOfOutline(outline: Line3[]): number {
    const outlineVectors = outline.map((line) => line.start);
    const outlineTriangles = outlineVectors.map((vector, index) => {
      const nextIndex = index + 1 === outlineVectors.length ? 0 : index + 1;
      return new Triangle(vector, outlineVectors[nextIndex], new Vector3());
    });

    const outlineTriangleAreas = outlineTriangles.map((triangle) => triangle.getArea());
    const outlineArea = outlineTriangleAreas.reduce((sum, area) => sum + area, 0);

    return outlineArea;
  }

  private _surfacesFromEdgeLineGroups(edgeLineGroups: Array<[Line3[][], Vector3]>, spaceId: string): PlanarSurface[] {
    const planarSurfaces = edgeLineGroups.map(([edgeLines, normal]) => {
      const biggestlineChunk = edgeLines.reduce((biggest, lineChunk) => {
        return this._areaOfOutline(lineChunk) > this._areaOfOutline(biggest) ? lineChunk : biggest;
      }, [] as Line3[]);

      const otherLinesChunks = edgeLines.filter((lineChunk) => lineChunk !== biggestlineChunk);
      const surfaceData: SurfaceData = new SurfaceData(biggestlineChunk, otherLinesChunks, normal.clone());

      return new PlanarSurface(surfaceData, this.apartment, undefined, this.room);
    });

    planarSurfaces.forEach((surface) => surface.connectSurfaces(planarSurfaces));

    return planarSurfaces;
  }

  private _setupStragglerSurfaces(surfaces: PlanarSurface[]): void {
    surfaces.forEach((surface) => {
      const connectedHashAndSurfaces = surface.getNeighborsAndHashes();

      if (connectedHashAndSurfaces.length === 0) return;
      const connectedHashResult = connectedHashAndSurfaces.find(([, surface]) => surface.surfaceType === SURFACE_TYPE.wall);
      if (!connectedHashResult) return;
      const [connectedHash, connectedWall] = connectedHashResult;

      const connectedEdge = connectedHash && connectedWall.getWorldEdgeFromHash(connectedHash);
      connectedEdge && connectedWall.setupConnectedSurface(surface, connectedEdge);
    });
    // Setup surfaces that weren't able to be connected to another surface
    surfaces.forEach((surface) => !surface.localOutline && surface.setupLocalSpace());
  }

  private _edgeLineGroupsFromMesh(mesh: Mesh): Array<[Line3[][], Vector3]> {
    if (!mesh?.geometry) {
      console.error(`Space ${this.spaceId} doesn't have any geometry`);
      return [];
    }
    if (!mesh?.geometry?.index) {
      console.error(`Space ${this.spaceId} doesn't have index buffer`);
      return [];
    }

    const faces = this._facesFromMesh(mesh);

    // if (["1xhcnhFGn6oPk94$IFLRav"].includes(this.spaceId)) {
    //   this._debugFaces(faces, mesh);
    // }

    const newOutlines = this._outlinesFromFaces(faces);

    return newOutlines;
  }

  private _allTrianglesFromMesh(mesh: Mesh): IndexedTriangle[] {
    const indices = mesh.geometry.index?.array as number[];
    const positions = (mesh.geometry.attributes.position as BufferAttribute).array as Float32Array;

    const triangles: IndexedTriangle[] = [];
    for (let i = 0; i < indices.length; i += 3) {
      const triangle = IndexedTriangle.fromPositionsAndIndices(positions, [indices[i], indices[i + 1], indices[i + 2]]);
      triangle.applyMatrix4(mesh.matrix);
      triangles.push(triangle);
    }
    return triangles;
  }

  /** get triangle index groups of triangles that are connected */
  private _facesFromMesh(mesh: Mesh): IndexedTriangle[][] {
    const allTriangles = this._allTrianglesFromMesh(mesh);
    const faceByIndices: Map<string, IndexedTriangle[]> = new Map();

    // Accumulate initial neighboring islands of triangles
    for (const triangle of allTriangles) {
      this._trianglesToFace([triangle], faceByIndices, triangle.getIndices());
    }

    // Get disparate islands of triangles together into the same face
    const entries = [...faceByIndices.entries()];
    for (const [indicesString, triangles] of entries) {
      const indices = indicesString.split(",").map((x) => parseInt(x));
      this._trianglesToFace(triangles, faceByIndices, indices);
    }

    return [...faceByIndices.entries()].map(([, value]) => value);
  }

  /** Combine neighboring triangles into faces */
  private _trianglesToFace(
    currTriangles: IndexedTriangle[],
    trianglesByIndices: Map<string, IndexedTriangle[]>,
    currIndices: number[]
  ) {
    const currIndexString = currIndices.join(",");

    for (const [otherIndicesString, otherTriangles] of trianglesByIndices.entries()) {
      if (currIndexString == otherIndicesString) continue;

      const otherIndices = otherIndicesString.split(",").map((x) => parseInt(x));
      const sharesPositionIndex = currIndices.some((index) => otherIndices.includes(index));

      if (!sharesPositionIndex) continue;

      trianglesByIndices.delete(otherIndicesString);
      trianglesByIndices.delete(currIndexString);
      const newIndices = [...currIndices, ...otherIndices];
      const newIndexString = newIndices.join(",");
      const newTriangles = otherTriangles.concat(currTriangles);

      trianglesByIndices.set(newIndexString, newTriangles);
      this._trianglesToFace(newTriangles, trianglesByIndices, newIndices);
      return;
    }
    // no other triangles share any indices with this one
    trianglesByIndices.set(currIndexString, currTriangles);
  }

  private _outlinesFromFaces(triangleGroups: IndexedTriangle[][]): [Line3[][], Vector3][] {
    const outputEdgeLines: [Line3[][], Vector3][] = [];
    triangleGroups.forEach((triangles) => {
      const edgelines = this._getOuterEdges(triangles);
      const normal = triangles[0].getNormal(new Vector3()).multiplyScalar(-1);
      outputEdgeLines.push([edgelines, normal]);
    });
    return outputEdgeLines;
  }

  private _getOuterEdges(triangles: Triangle[]): Line3[][] {
    const edgeHashes = new Set<string>();
    const outerEdges: Line3[] = [];

    for (const triangle of triangles) {
      for (let i = 0; i < 3; i++) {
        const end = triangle.getCornerByIndex(i);
        const start = triangle.getCornerByIndex((i + 1) % 3);

        const edgeHash = `${start.x},${start.y},${start.z},${end.x},${end.y},${end.z}`;
        const reverseEdgeHash = `${end.x},${end.y},${end.z},${start.x},${start.y},${start.z}`;

        if (edgeHashes.has(edgeHash)) edgeHashes.delete(edgeHash);
        else if (edgeHashes.has(reverseEdgeHash)) edgeHashes.delete(reverseEdgeHash);
        else edgeHashes.add(edgeHash);
      }
    }

    // Convert the dictionary of edges back into an array of Line3 objects
    for (const edgeHash of edgeHashes) {
      const pointsFromHash = edgeHash.split(",");
      const line = new Line3(
        new Vector3(parseFloat(pointsFromHash[0]), parseFloat(pointsFromHash[1]), parseFloat(pointsFromHash[2])),
        new Vector3(parseFloat(pointsFromHash[3]), parseFloat(pointsFromHash[4]), parseFloat(pointsFromHash[5]))
      );
      outerEdges.push(line);
    }
    const edgeGroups: any[] = [];

    let connectedEdges: any = [];
    let unconnectedEdges = outerEdges;
    while (unconnectedEdges.length) {
      [connectedEdges, unconnectedEdges] = this._connectEdges(unconnectedEdges);
      connectedEdges.length && edgeGroups.push(connectedEdges);
    }
    return edgeGroups;
  }

  private _connectEdges(unconnectedEdges: Line3[]): Line3[][] {
    if (!unconnectedEdges.length) return [[], []];

    const connectedEdges: Line3[] = [];

    connectedEdges.push(unconnectedEdges.shift()!);

    const maxTries = 2500; // TODO: Detect when edges connect instead
    let currentTry = 0;
    while (unconnectedEdges.length > 0) {
      let currentEdge = unconnectedEdges.shift()!;
      const startStartConnected = connectedEdges.find((connected) => connected.start.equals(currentEdge.start));
      if (startStartConnected) {
        currentEdge = new Line3(currentEdge.end, currentEdge.start);
        const index = connectedEdges.indexOf(startStartConnected);
        connectedEdges.splice(index, 0, currentEdge);
        continue;
      }

      const startEndConnected = connectedEdges.find((connected) => connected.start.equals(currentEdge.end));
      if (startEndConnected) {
        const index = connectedEdges.indexOf(startEndConnected);
        connectedEdges.splice(index, 0, currentEdge);
        continue;
      }

      const endStartConnected = connectedEdges.find((connected) => connected.end.equals(currentEdge.start));
      if (endStartConnected) {
        const index = connectedEdges.indexOf(endStartConnected);
        connectedEdges.splice(index + 1, 0, currentEdge);
        continue;
      }

      const endEndConnected = connectedEdges.find((connected) => connected.end.equals(currentEdge.end));
      if (endEndConnected) {
        currentEdge = new Line3(currentEdge.end, currentEdge.start);
        const index = connectedEdges.indexOf(endEndConnected);
        connectedEdges.splice(index + 1, 0, currentEdge);
        continue;
      }

      unconnectedEdges.push(currentEdge);

      if (currentTry > maxTries) break;

      currentTry++;
    }
    return [connectedEdges, unconnectedEdges];
  }

  private _debugFaces(faces: IndexedTriangle[][], mesh: Mesh): void {
    const colorTable = [0xff0000, 0x00ff00, 0x0000ff, 0xffff00, 0xff00ff, 0x00ffff, 0x000000];

    const normalsFlat = (mesh.geometry.attributes.normal as BufferAttribute).array as number[];
    const normals: Vector3[] = [];
    for (let i = 0; i < normalsFlat.length; i += 3) {
      normals.push(new Vector3(normalsFlat[i], normalsFlat[i + 1], normalsFlat[i + 2]));
    }
    let faceIndex = 0;
    for (const face of faces) {
      const color = colorTable[faceIndex % colorTable.length];
      faceIndex++;

      face.forEach((triangle) => {
        const worldPosition0 = triangle.a.clone();
        const worldPosition1 = triangle.b.clone();
        const worldPosition2 = triangle.c.clone();

        const triangleCenterPos = worldPosition0.clone().add(worldPosition1).add(worldPosition2).divideScalar(3);

        const amountToMove = 0.17;

        worldPosition0.add(triangleCenterPos.clone().sub(worldPosition0).multiplyScalar(amountToMove));
        worldPosition1.add(triangleCenterPos.clone().sub(worldPosition1).multiplyScalar(amountToMove));
        worldPosition2.add(triangleCenterPos.clone().sub(worldPosition2).multiplyScalar(amountToMove));

        worldPosition0.add(normals[triangle.aIndex].clone().multiplyScalar(-amountToMove));
        worldPosition1.add(normals[triangle.bIndex].clone().multiplyScalar(-amountToMove));
        worldPosition2.add(normals[triangle.cIndex].clone().multiplyScalar(-amountToMove));

        const lines: Line3[] = [
          new Line3(worldPosition0, worldPosition1),
          new Line3(worldPosition1, worldPosition2),
          new Line3(worldPosition2, worldPosition0),
        ];

        createTextHelper(`${triangle.aIndex}`, worldPosition0, color, 0.02, normals[triangle.aIndex]);
        createTextHelper(`${triangle.bIndex}`, worldPosition1, color, 0.02, normals[triangle.bIndex]);
        createTextHelper(`${triangle.cIndex}`, worldPosition2, color, 0.02, normals[triangle.cIndex]);
        createLinesHelper(lines, color);
      });
    }
  }

  private _debugOutlines(outlines: Array<[Line3[][], Vector3]>): void {
    const colorTable = [0xff0000, 0x00ff00, 0x0000ff, 0xffff00, 0xff00ff, 0x00ffff, 0x000000];

    outlines.forEach(([lines, normal], outlineIndex) => {
      const shiftedNormal = normal.clone().addScalar(1).multiplyScalar(0.5);
      const color = new Color(shiftedNormal.x, shiftedNormal.y, shiftedNormal.z);
      const randomColor = colorTable[outlineIndex % colorTable.length];

      lines[0].forEach((line) => {
        const offset = normal.clone().multiplyScalar(-0.1);
        const startOffset = line.start.clone().add(offset);
        const endOffset = line.end.clone().add(offset);
        createLinesHelper([new Line3(startOffset, endOffset)], randomColor);
      });
    });
  }
}
