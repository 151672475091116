import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  MeshStandardMaterial,
  Mesh,
  Vector3,
  Object3D,
  TextureLoader,
  RepeatWrapping,
  Euler,
  PMREMGenerator,
  WebGLRenderTarget,
  EquirectangularReflectionMapping,
  sRGBEncoding,
  NoToneMapping,
  MeshBasicMaterial,
  Vector2,
  Color,
  LinearEncoding,
  FrontSide,
  Vector3Tuple,
} from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { EXRLoader } from "three/examples/jsm/loaders/EXRLoader.js";
import { CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { ACESFilmicToneMappingShader } from "three/examples/jsm/shaders/ACESFilmicToneMappingShader.js";
import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass.js";
import { getObjectsByType } from "@/three/ThreeJsHelpers";
import { Building } from "@/three/building-objects/Building";
import { Apartment } from "@/three/building-objects/Apartment";
import "./extensions/MeshExtensions";
import "./extensions/GeometryExtensions";
import "./extensions/Vector3Extensions";
import "./extensions/Vector2Extensions";
import "./extensions/Line3Extensions";
import "./extensions/TriangleExtensions";
import "./extensions/QuaternionExtensions";
import { apartmentEventDispatcher } from "@/events/apartments";
import store from "@/store";
import { addApartments } from "@/store/apartments";
import * as clipperLib from "js-angusj-clipper/web";
import * as Triangle from "triangle-wasm";

export enum Object3DEventType {
  Destroyed = "ondestroyed",
}

export let clipper: clipperLib.ClipperLibWrapper | undefined;

// Temporarily created as a singleton until a better pattern will be decided on
export class ThreeJsContext {
  private static instance: ThreeJsContext;
  public readonly domElement: HTMLElement;
  public readonly camera: PerspectiveCamera;
  public readonly renderer: WebGLRenderer;
  public defaultFloorMaterial?: MeshStandardMaterial;
  public defaultWallMaterial?: MeshStandardMaterial;
  public uvCheckerMaterial?: MeshBasicMaterial;
  public spacesAndOpeningsMaterial?: MeshStandardMaterial;
  public scene: Scene;
  public orbitControls: OrbitControls;
  public ifcRoot?: Object3D;
  public currentApartments: Apartment[] = [];
  private buttonsRenderer: CSS2DRenderer;
  private gltfLoader: GLTFLoader;
  private buildings: Building[] = [];
  private cachedAssets: Record<string, Array<Object3D>> = {};
  private composer: EffectComposer;
  public readonly outlinePass: OutlinePass;

  private resizeListener: () => void;

  public static createInstance(parentElement: HTMLElement) {
    this.instance = new ThreeJsContext();
    this.instance.renderToDom(parentElement);
  }

  public static disposeInstance() {
    if (this.instance) this.instance.dispose();
  }

  public static getInstance(): ThreeJsContext {
    if (!this.instance) throw new Error("Canvas instance not created yet");
    return this.instance;
  }

  public constructor() {
    ThreeJsContext.instance = this;

    apartmentEventDispatcher.addEventListener("showCurrentApartment", function (event) {
      ThreeJsContext.instance._showCurrentApartments(event.apartmentName, event.focusCamera);
    });

    this.gltfLoader = new GLTFLoader();
    this.camera = new PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.3, 1000);

    this.scene = new Scene();
    this.renderer = new WebGLRenderer({ antialias: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.toneMapping = NoToneMapping;

    this.domElement = this.renderer.domElement;

    this.buttonsRenderer = new CSS2DRenderer();
    this.buttonsRenderer.setSize(window.innerWidth, window.innerHeight);
    this.buttonsRenderer.domElement.style.position = "absolute";
    this.buttonsRenderer.domElement.style.top = "0px";
    this.buttonsRenderer.domElement.style.pointerEvents = "none";
    document.body.appendChild(this.buttonsRenderer.domElement);

    this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);
    this.orbitControls.enablePan = true;
    this.orbitControls.rotateSpeed = 0.5;
    this.orbitControls.target = new Vector3(0, 0, 0);
    this.orbitControls.update();

    this.camera.position.x = 20;
    this.camera.position.y = 40;
    this.camera.position.z = 40;

    const size = this.renderer.getDrawingBufferSize(new Vector2());
    // if (WEBGL.isWebGL2Available()) {
    //   const renderTarget = new WebGLMultisampleRenderTarget(size.width, size.height);
    //   this.composer = new EffectComposer(this.renderer, renderTarget);
    // } else {
    this.composer = new EffectComposer(this.renderer);
    // }

    const renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(renderPass);

    this.outlinePass = new OutlinePass(new Vector2(window.innerWidth, window.innerHeight), this.scene, this.camera);
    this.outlinePass.edgeStrength = 20;
    this.outlinePass.edgeGlow = 0;
    this.outlinePass.edgeThickness = 1;
    this.outlinePass.visibleEdgeColor.set(new Color(0x00ff00));
    this.outlinePass.hiddenEdgeColor.set(0x001000);

    const tonemappingPass = new ShaderPass(ACESFilmicToneMappingShader);
    tonemappingPass.uniforms["exposure"].value = 3;

    const gammaCorrection = new ShaderPass(GammaCorrectionShader);

    this.composer.addPass(tonemappingPass);
    this.composer.addPass(gammaCorrection);
    this.composer.addPass(this.outlinePass);

    this.composer.renderTarget1.texture.encoding = sRGBEncoding;
    this.composer.renderTarget2.texture.encoding = sRGBEncoding;

    this.renderer.setAnimationLoop(() => {
      this.animate();
    });

    window.getCurrentCameraTransform = () => {
      return {
        position: this.camera.position.toArray(),
        rotation: this.camera.rotation.toArray(),
      };
    };

    window.setCurrentCameraTransform = ({
      position,
      rotation,
    }: {
      position: Vector3Tuple;
      rotation: [number, number, number, undefined];
    }) => {
      this.camera.position.copy(new Vector3().fromArray(position));
      this.camera.rotation.copy(new Euler().fromArray(rotation));
      this.orbitControls.update();
    };

    this.resizeListener = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);

      const width = window.innerWidth;
      const height = window.innerHeight;

      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();

      // TODO: use setScissor to only show pixels that are unobscured by dom elements
      this.renderer.setSize(width, height);
      this.buttonsRenderer.setSize(width, height);

      this.composer.setSize(width, height);
    };
    this.addEventListeners();
  }

  public async initialize() {
    this.loadEnvironment("assets/Ultimate_Skies_12k_0045_sm.exr");
    await this.loadDefaultMaterials();
    this.defaultFloorMaterial!.side = FrontSide;
    this.defaultFloorMaterial!.name = "defaultFloorMaterial";
    this.defaultWallMaterial!.side = FrontSide;
    this.defaultWallMaterial!.name = "defaultWallMaterial";
    this.uvCheckerMaterial!.side = FrontSide;
    this.uvCheckerMaterial!.name = "uvCheckerMaterial";
  }

  public renderToDom(parentElement: HTMLElement) {
    parentElement.appendChild(this.renderer.domElement);
  }

  public async loadProject(project: string): Promise<void> {
    const processedGlbUrlPromise = this.getProcessedGlbUrl(`${process.env.VUE_APP_BASE_URL}/builder/projects/${project}/scene`);

    let wasAbleToLoad = false;
    const processedGlbUrl = await processedGlbUrlPromise;
    if (processedGlbUrl) {
      wasAbleToLoad = await this.addIfcMeshesToScene(processedGlbUrl);
    }

    if (!wasAbleToLoad) {
      // This is a fallback for when the processed glb is not available
      store.commit(addApartments, [{ name: "null", id: null }]);
    }
  }

  private async loadDefaultMaterials(): Promise<any> {
    const defaultFloorUrl = "assets/DefaultFloor.glb";
    const defaultWallUrl = "assets/DefaultWall.glb";

    this.uvCheckerMaterial = new MeshBasicMaterial({
      color: 0xffffff,
      envMap: null,
      toneMapped: false,
    });

    const floorPromise = this.returnFirstMaterialFromGltf(defaultFloorUrl)
      .then((material) => (this.defaultFloorMaterial = material))
      .catch((err) => {
        throw new Error(`Unable to load default floor ${err}`);
      });

    const wallPromise = this.returnFirstMaterialFromGltf(defaultWallUrl)
      .then((material) => (this.defaultWallMaterial = material))
      .catch((err) => {
        throw new Error(`Unable to load default wall ${err}`);
      });

    new TextureLoader()
      .loadAsync("assets/UV-Checker.jpg")
      .then((texture) => {
        texture.encoding = sRGBEncoding;
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;
        texture.flipY = false;
        this.uvCheckerMaterial!.map = texture;
      })
      .catch((err) => {
        throw new Error(`Unable to load UV checker ${err}`);
      });

    return Promise.all([floorPromise, wallPromise]);
  }

  private returnFirstMaterialFromGltf(url: string): Promise<MeshStandardMaterial> {
    return new Promise((resolve) => {
      this.gltfLoader
        .loadAsync(url)
        .then((gltf) => {
          gltf.scene.traverse((child: Object3D) => {
            const mesh = child as Mesh;
            if (mesh.isMesh) {
              resolve(mesh.material as MeshStandardMaterial);
            }
          });
        })
        .catch((err) => {
          throw new Error(`Unable to load IFC gltf ${err}`);
        });
    });
  }

  private async getProcessedGlbUrl(glbUrl: string): Promise<string | undefined | void> {
    return fetch(glbUrl, { method: "GET" })
      .then((response) => {
        if (!response.ok) {
          return undefined;
        } else {
          return response.url;
        }
      })
      .catch((err) => {
        console.error("Couldn't get response from server", err);
      });
  }

  private async loadEnvironment(url: string): Promise<void> {
    return new Promise((resolve) => {
      const pmremGenerator = new PMREMGenerator(this.renderer);
      pmremGenerator.compileEquirectangularShader();

      const scene = this.scene;
      new EXRLoader().load(url, function (texture) {
        const exrCubeRenderTarget: WebGLRenderTarget = pmremGenerator.fromEquirectangular(texture);

        texture.mapping = EquirectangularReflectionMapping;
        scene.background = texture;
        scene.background.encoding = LinearEncoding;
        scene.environment = exrCubeRenderTarget.texture;

        pmremGenerator.dispose();
        resolve();
      });
    });
  }

  private addEventListeners(): void {
    this.resizeListener();
    window.addEventListener("resize", this.resizeListener);
  }

  private disposeEventListeners(): void {
    window.removeEventListener("resize", this.resizeListener, false);
  }

  public dispose(): void {
    this.disposeEventListeners();
    // TODO: Add THREE disposal functions to scenegraph
    // TODO: Dispose THREE renderer
  }

  public animate(): void {
    this.renderer.render(this.scene, this.camera);
    this.buttonsRenderer.render(this.scene, this.camera);
    this.composer.render();
  }

  public _showCurrentApartments(apartment: string | undefined, focusCamera = true) {
    if (apartment) {
      let apartments: Apartment[] = [];

      this.buildings.map((building) => {
        apartments = apartments.concat(building.getApartments());
      });

      this.currentApartments = apartments.filter((apt) => apt.currentName() === apartment);
    } else {
      this.currentApartments = [];
    }

    const apartments = this.getApartments();

    apartments.forEach((apt) => {
      apt.showAssets(false);
      apt.showSurfaces(false);
    });

    if (!this.currentApartments.length) return;

    this.currentApartments.forEach((apt) => {
      apt.showAssets(true);
      apt.showSurfaces(true);
    });

    focusCamera && this.centerCameraOnMeshes(this.currentApartments.map((apt) => apt.spaceMesh));

    console.log(`Have selected apartment: ${apartment}`);
  }

  public getApartments(): Apartment[] {
    let apartments: Apartment[] = [];

    this.buildings.forEach((building) => {
      apartments = apartments.concat(building.getApartments());
    });

    return apartments;
  }

  private centerCameraOnMeshes(meshes: Mesh[]): void {
    meshes = meshes.filter((mesh) => mesh.isMesh);
    if (!meshes.length) {
      console.warn("No meshes to center camera on");
      return;
    }

    const position = new Vector3();
    const cameraPosition = new Vector3();
    const size = new Vector3();

    meshes.forEach((mesh) => {
      const abb = mesh.getCurrentWorldBounds();
      position.add(abb.getCenter(new Vector3()));
      size.add(abb.getSize(new Vector3()));
    });
    position.divideScalar(meshes.length);
    size.divideScalar(meshes.length);

    cameraPosition.add(position);
    cameraPosition.add(new Vector3(1, 1, 1).multiplyScalar(size.length()));

    this.camera.position.copy(cameraPosition);

    this.orbitControls.target = position;
    this.orbitControls.update();
  }

  private async loadIfcScene(modelName: string): Promise<Object3D> {
    return this.gltfLoader
      .loadAsync(modelName)
      .then((gltf) => gltf.scene)
      .catch((err) => {
        throw new Error(`Unable to load IFC gltf ${err}`);
      });
  }

  private async loadClipper(): Promise<clipperLib.ClipperLibWrapper> {
    return clipperLib.loadNativeClipperLibInstanceAsync(clipperLib.NativeClipperLibRequestedFormat.WasmWithAsmJsFallback);
  }

  // TODO: Move to IFC parsing class
  private async addIfcMeshesToScene(modelName: string): Promise<any> {
    const [ifcScene, clipperResult, triangleResult] = await Promise.all([
      this.loadIfcScene(modelName),
      this.loadClipper(),
      Triangle.init("lib/triangle.out.wasm"),
    ]);
    clipper = clipperResult;

    ifcScene.updateMatrixWorld();

    const doors = getObjectsByType("IfcDoor", ifcScene) as Mesh[];
    const windows = getObjectsByType("IfcWindow", ifcScene) as Mesh[];
    const openings = doors.concat(windows);

    openings.forEach((opening) => {
      opening.visible = false;
    });

    this.scene.add(ifcScene);
    const openingsParent: Object3D | undefined = getObjectsByType("IfcOpeningElements")[0];

    openingsParent && this.scene.add(openingsParent);

    ifcScene.traverse((gltfChild: Object3D) => {
      const childMesh = gltfChild as Mesh;
      if (childMesh.isMesh) {
        const geom = childMesh.geometry;
        if (!geom.attributes.normal) geom.computeVertexNormals();
      }
    });

    ifcScene.traverse((gltfChild: Object3D) => {
      if (gltfChild.name.startsWith("Root")) {
        this.ifcRoot = gltfChild;
      }
    });

    if (!this.ifcRoot) {
      console.error("No IFC root found");
      return false;
    }

    const buildingObjects = getObjectsByType("IfcBuilding");
    this.buildings = buildingObjects.map((building) => {
      return new Building(building);
    });

    if (!this.buildings.length) console.error("No buildings in IFC Gltf file");

    const hasApartments = this.buildings.flatMap((building) => building.getApartments()).length > 0;

    return this.buildings.length > 0 && hasApartments;
  }

  public async placeGltf(url: string, position: Vector3, rotation: Euler, newParent?: Object3D): Promise<Object3D> {
    const gltfScene = await this.getGltfScene(url);
    gltfScene.position.copy(position);
    gltfScene.rotation.copy(rotation);
    newParent = newParent ? newParent : this.scene;
    gltfScene && newParent.add(gltfScene);

    // Assign eventlisteners to the Object3D
    newParent.addEventListener(Object3DEventType.Destroyed, () => {
      const i = this.cachedAssets[url].findIndex((o) => o === gltfScene);
      if (i >= 0) {
        this.cachedAssets[url].splice(i, 1);
      } else {
        throw new Error("asset not found");
      }
      if (this.cachedAssets[url].length === 0) {
        this.disposeObject3D(gltfScene);
      }
    });

    return gltfScene;
  }

  public async loadGltf(url: string): Promise<Object3D | undefined> {
    if (!url) return undefined;

    const gltfScene = await this.getGltfScene(url);

    // Assign eventlisteners to the Object3D
    gltfScene.addEventListener(Object3DEventType.Destroyed, () => {
      const i = this.cachedAssets[url].findIndex((o) => o === gltfScene);
      if (i >= 0) {
        this.cachedAssets[url].splice(i, 1);
      } else {
        throw new Error("asset not found");
      }
      if (this.cachedAssets[url].length === 0) {
        this.disposeObject3D(gltfScene);
      }
    });

    return gltfScene;
  }

  private async getGltfScene(url: string): Promise<Object3D> {
    let scene: Object3D;
    if (url in this.cachedAssets && this.cachedAssets[url].length > 0) {
      scene = this.cachedAssets[url][0].clone();
    } else {
      try {
        const gltfScene = await this.gltfLoader.loadAsync(url);
        scene = gltfScene.scene;
        if (!(url in this.cachedAssets)) {
          this.cachedAssets[url] = [];
        }
      } catch (err) {
        throw new Error(`Unable to load GLTF gltf ${err}`);
      }
    }
    this.cachedAssets[url].push(scene);
    return scene;
  }

  private disposeObject3D(object3D: any): void {
    if (object3D.children) {
      for (let i = 0; i < object3D.children.length; i += 1) {
        this.disposeObject3D(object3D.children[i]);
      }
    }

    if (object3D.geometry) {
      object3D.geometry.dispose();
    }

    // ! all other texture buffers except albedo leak here. This is dangerous!
    if (object3D.material) {
      if (object3D.material.map) {
        object3D.material.map.dispose();
      }
      object3D.material.dispose();
    }
  }
}
